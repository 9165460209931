@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
/** General **/
body {
  margin: 0;
  font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-teal {
  color: #1ba97f;
}

.bg-teal {
  background-color: #afecda;
}

.btn-teal {
  color: #ffffff;
  background-color: #1ba97f;
  border-color: #1ba97f
}

.btn-teal:hover {
  color: #ffffff;
  background-color: #168967;
}

.btn-outline-teal {
  color: #1ba97f;
  border-color: #1ba97f;
}

.btn-outline-teal:hover {
  color: #ffffff;
  background-color: #1ba97f;
}

.btn-teal:focus, .btn-outline-teal:focus {
  box-shadow: 0 0 0 3px rgba(27, 169, 127, .3);
}

/** Navbar **/
.navbar {
  min-height: 8rem;
}

.bg-blur {
  background-color: rgba(255,255,255,0.5);
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
}

.brief {
  margin-top: 9rem;
  margin-bottom: 1rem;
}

/** QuestionList **/
.question-number {
  min-width: 1.8em;
  margin-right: 0.25em;
  font-weight: bold;
  text-align: center;
}

.form-check-input {
  min-width: 1.25em;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #1ba97f !important;
  border-color: #1ba97f !important;
}

.form-check-input:focus {
  box-shadow: 0 0 0 3px rgba(27, 169, 127, .3) !important;
  border-color: #1ba97f !important;
}

.list-group-item-action {
  cursor: pointer;
}

/** Results **/
.result {
  margin-top: 1rem;
}

.progress {
  height: 1.4rem;
}

.nav-pills {
  cursor: pointer;
}
